import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`For v1 Docs, visit `}<a parentName="p" {...{
          "href": "https://mukuljainx.github.io/smart-grid/"
        }}>{`here`}</a></p>
    </blockquote>
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Smart grid is light weight simple and ready to use hooks to build any kind of grid/table! As it
exposes everything to developer its up to developer what gird will consist of and how things will function.
No more relying on libraries to provide any custom feature apart from core functionality.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const { onScroll, rowRenderer } = useGrid({
  data: state.loading ? state.data.concat([null, null]) : state.data,
  rowHeight: rowHeight || 39,
  buffer,
  limit,
  loadMore: getData,
  virtualized,
});

return (
  <table role="table">
    <thead>
      <tr role="row">
        <th role="cell" colSpan={4}></th>
        <th role="cell" colSpan={3}>
          Car
        </th>
      </tr>
      <tr role="row">
        <th colSpan={1} role="cell">
          First Name
        </th>
        <th colSpan={1} role="cell">
          Last Name
        </th>
        <th colSpan={1} role="cell">
          Age
        </th>
        <th colSpan={1} role="cell">
          Email
        </th>
        <th colSpan={1} role="cell">
          Make
        </th>
        <th colSpan={1} role="cell">
          Model
        </th>
        <th colSpan={1} role="cell">
          Year
        </th>
      </tr>
    </thead>
    <tbody role="rowgroup" onScroll={onScroll}>
      {rowRenderer((row, style, index, ref) =>
        row ? (
          <tr
            role="row"
            ref={ref}
            className="table-row"
            data-testid={\`table-row-\${index}\`}
            style={style}
            key={index}
          >
            <td role="cell">{row.firstName}</td>
            <td role="cell">{row.lastName}</td>
            <td role="cell">{row.age}</td>
            <td role="cell">{row.email}</td>
            <td role="cell">{row.carMake}</td>
            <td role="cell">{row.carModel}</td>
            <td role="cell">{row.carYear}</td>
          </tr>
        ) : (
          <tr
            ref={ref}
            className="table-row loading"
            data-testid={\`table-row-\${index}\`}
            style={style}
            key={index}
          >
            <td role="cell">Loading</td>
          </tr>
        )
      )}
    </tbody>
  </table>
);
`}</code></pre>
    <p>{`Yup that's it! 🚀`}</p>
    <p>{`It gets even better, the two hooks `}<inlineCode parentName="p">{`useGrid`}</inlineCode>{` and `}<inlineCode parentName="p">{`useGrids`}</inlineCode>{` are just stitching of few internal
hooks which are exposed too 🎉! Though most use cases should be covered with `}<inlineCode parentName="p">{`useGrid`}</inlineCode>{` and `}<inlineCode parentName="p">{`useGrids`}</inlineCode>{`
you can hack the core to solve a totally unique use case.`}</p>
    <h1 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h1>
    <ul>
      <li parentName="ul">{`🪄 Virtualization`}</li>
      <li parentName="ul">{`🎲 Dynamic Height`}</li>
      <li parentName="ul">{`📌 Pinning`}</li>
      <li parentName="ul">{`∞ Infinite Scroll`}</li>
      <li parentName="ul">{`🛠 Highly configurable`}</li>
      <li parentName="ul">{`💪 TypeScript`}</li>
      <li parentName="ul">{`⚛ `}<a parentName="li" {...{
          "href": "https://bundlephobia.com/package/@crafts/smart-grid@latest"
        }}>{`Pretty small`}</a></li>
      <li parentName="ul">{`🚀 Faster and lighter than V1`}</li>
      <li parentName="ul">{`🎁 Out of the box Grid, just provide schema and data (TBA)`}</li>
      <li parentName="ul">{`and much more 🔥`}</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Get started now!`}</a></p>
    <h3 {...{
      "id": "used-by",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#used-by",
        "aria-label": "used by permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Used by`}</h3>
    <p><a parentName="p" {...{
        "href": "http://innovaccer.com/"
      }}><img parentName="a" {...{
          "src": "./users/innovaccer.svg",
          "alt": null
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      